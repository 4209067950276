import moment from 'moment';

const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const isValidDayOfWeek = (dayOfWeek: string): boolean => {
  return WEEKDAYS.indexOf(dayOfWeek) > -1;
};

class NaiveDate {
  _moment: moment.Moment

  constructor(momentObject: moment.Moment) {
    this._moment = momentObject;
  }

  static fromString(s: string): NaiveDate {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(s)) {
      throw new Error('Invalid NaiveDate string');
    }
    // Unlike `new Date(s)`, `moment(s)` does _not_ set to UTC midnight.
    // Therefore with `moment(s)` calculations are generally what we want.
    // For example, `moment(s).format('dddd')` will give us the actual day of the week
    // whereas `moment(new Date(s)).format('dddd')` will give us the local day of the
    // week for midnight UTC of the date represented by `s` (which is generally wrong).
    return new NaiveDate(moment(s));
  }

  asString(): string {
    return this._moment.format('YYYY-MM-DD');
  }

  dayOfWeek(): string {
    return this._moment.format('dddd');
  }

  getNextNaiveDateOnDayOfWeek(dayOfWeek: string): NaiveDate {
    if (!isValidDayOfWeek(dayOfWeek)) {
      throw new Error('Invalid weekday');
    }
    const newMoment = moment(this._moment);
    while (newMoment.format('dddd') !== dayOfWeek) {
      newMoment.add(1, 'day');
    }
    return new NaiveDate(newMoment);
  }
}

export default NaiveDate;