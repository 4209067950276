const zeroPad = (num: number): string => {
  return ('0' + num).slice(-2);
}

// Represents a time as if in the format
// HH:MM:SS
class NaiveTime {
  _hours: number
  _minutes: number
  _seconds: number

  constructor(hours: number, minutes: number, seconds: number) {
    this._hours = hours;
    this._minutes = minutes;
    this._seconds = seconds;
  }

  static fromString(s: string): NaiveTime {
    const parts = s.split(':');
    // TODO: Check that each part parses correctly.
    if (parts.length === 2) {
      const [hours, minutes] = parts.map((p) => parseInt(p, 10));
      return new NaiveTime(hours, minutes, 0);
    } else if (parts.length === 3) {
      const [hours, minutes, seconds] = parts.map((p) => parseInt(p, 10));
      return new NaiveTime(hours, minutes, seconds);
    }
    throw new Error('Invalid NaiveTime string');
  }

  asString(): string {
    return `${zeroPad(this._hours)}:${zeroPad(this._minutes)}:${zeroPad(this._seconds)}`
  }

  isAm(): boolean {
    return this._hours < 12;
  }

  getClockHours(): number {
    const hoursMod = this._hours % 12;
    return hoursMod === 0 ? 12 : hoursMod;
  }

  addMinutes(minutes: number): NaiveTime {
    const sumMinutes = this._minutes + minutes;
    const newHours = (this._hours + Math.round(sumMinutes / 60)) % 24;
    const newMinutes = sumMinutes % 60;
    return new NaiveTime(newHours, newMinutes, this._seconds);
  }
}

export default NaiveTime;