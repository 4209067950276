import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import RbNavbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { NavLink } from 'react-router-dom';

function Navbar({
  isAuthenticated,
  signOut,
}: {
  isAuthenticated: boolean,
  signOut: () => void,
}) {
  const getSigninOrSignout = () => {
    if (isAuthenticated) {
      return (
        <RbNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink to="/providers" className="nav-link">Providers</NavLink>
          </Nav>
          <Button onClick={signOut} variant="outline-danger">Sign Out</Button>
        </RbNavbar.Collapse>
      );
    } else {
      return (
        <RbNavbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Item>
              <NavLink to="/login" className="nav-link">Sign In</NavLink>
            </Nav.Item>
          </Nav>
        </RbNavbar.Collapse>
      );
    }
  }

  const signinout = getSigninOrSignout();

  return (
    <RbNavbar bg="light" expand="lg">
      <Container fluid>
        <LinkContainer to="/">
          <RbNavbar.Brand>Evme Admin</RbNavbar.Brand>
        </LinkContainer>
        <RbNavbar.Toggle aria-controls="basic-navbar-nav" />
        { signinout }
      </Container>
    </RbNavbar>
  );
}

export default Navbar;