import { useEffect, useState } from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer, View as RbcView } from 'react-big-calendar';

import ProviderScheduleSlot from 'webapp-common/models/ProviderScheduleSlot';
import DayOfWeek, { DAYS_OF_WEEK } from 'webapp-common/types/DayOfWeek';

import { getDateFromNaiveDateAndTimeAndTimeZone } from '../utils/time_zones';

// CSS for react-big-calendar
import 'react-big-calendar/lib/css/react-big-calendar.css';

const getDefaultStartAndEndDates = (): {start: Date, end: Date} => {
  // From Sunday to Sunday
  const start = moment().startOf('month').day(0).toDate();
  const end = moment().endOf('month').day(6).toDate();
  return { start, end };
};

const getStartAndEndDatesFromRange = (range: Date[] | {start: Date, end: Date}) => {
  if (Array.isArray(range)) {
    if (range.length === 1) {
      // Day view
      const start = range[0];
      const end = moment(start).endOf('day').toDate();
      return { start, end }
    } else {
      // Week view
      const start = range[0];
      const end = moment(range[range.length - 1]).endOf('day').toDate();
      return { start, end };
    }
  } else {
    // Month or Agenda views
    const { start, end } = range;
    return { start, end };
  }
};

interface CalendarEvent {
  title?: string,
  start: Date,
  end: Date,
};

const getDayIndexFromDayOfWeek = (dow: DayOfWeek) => {
  return DAYS_OF_WEEK.indexOf(dow);
};

const getInitialPlacementStartDate = async (slot: ProviderScheduleSlot) => {
  const slotStartDate = await getDateFromNaiveDateAndTimeAndTimeZone(slot.start_date, slot.start_time, 'America/Los_Angeles');
  const slotStartMoment = moment(slotStartDate);
  const placementDay = getDayIndexFromDayOfWeek(slot.placement_day);
  if (placementDay <= slotStartMoment.day()) {
    // This week
    slotStartMoment.day(placementDay);
  } else {
    // Last week.
    slotStartMoment.day(placementDay - 7);
  }
  return slotStartMoment;
};

const getSlotEndDate = async (slot: ProviderScheduleSlot) => {
  if (!slot.end_date) {
    return null;
  }
  const slotEnd = await getDateFromNaiveDateAndTimeAndTimeZone(slot.end_date, '0:00:00', 'America/Los_Angeles');
  const slotEndMoment = moment(slotEnd);
  slotEndMoment.add(1, 'day');
  return slotEndMoment.toDate();
};

const getEffectiveEndDate = async (slot: ProviderScheduleSlot, end: Date) => {
  const slotEndDate = await getSlotEndDate(slot);
  if (!slotEndDate) {
    return end;
  }
  if (slotEndDate.getTime() < end.getTime()) {
    return slotEndDate;
  } else {
    return end;
  }
};


class WeeklySlot {
  title: string
  start: moment.Moment
  startDate: Date
  startTime: number
  end: moment.Moment
  endTime: number
  duration: number

  constructor(title: string, start: moment.Moment, duration: number) {
    this.title = title;
    this.start = start;
    this.startDate = start.toDate();
    this.startTime = this.startDate.getTime();
    this.end = moment(this.start).add(duration, 'minutes');
    this.endTime = this.end.toDate().getTime();
    this.duration = duration;
  }

  advanceWeek() {
    this.start.add(7, 'days');
    this.startDate = this.start.toDate();
    this.startTime = this.startDate.getTime();
    this.end = moment(this.start).add(this.duration, 'minutes');
    this.endTime = this.end.toDate().getTime();
  }

  startsAfterTime(time: number) {
    return this.startTime >= time;
  }

  endsBeforeTime(time: number) {
    return this.endTime <= time;
  }

  getCalendarEvent(): CalendarEvent {
    return {
      title: this.title,
      start: this.start.toDate(),
      end: this.end.toDate(),
    };
  }
}


const getCalendarEventsFromSlot = async (slot: ProviderScheduleSlot, range: {start: Date, end: Date}): Promise<CalendarEvent[]> => {
  const events = [];

  const effectiveEndDate = await getEffectiveEndDate(slot, range.end);
  const effectiveEndTime = effectiveEndDate.getTime();

  const placementStartMoment = await getInitialPlacementStartDate(slot);
  const readingOneStartMoment = moment(placementStartMoment).add(2, 'days');
  const readingTwoStartMoment = moment(readingOneStartMoment).add(2, 'days');

  const weeklySlots = [
    new WeeklySlot('Patch Placement', placementStartMoment, slot.duration),
    new WeeklySlot('Reading #1', readingOneStartMoment, slot.duration),
    new WeeklySlot('Reading #2', readingTwoStartMoment, slot.duration),
  ];

  const startTime = range.start.getTime();
  while (true) {
    let shouldBreak = false;
    for (const weeklySlot of weeklySlots) {
      if (weeklySlot.startsAfterTime(effectiveEndTime)) {
        shouldBreak = true;
        break;
      }
      if (!weeklySlot.endsBeforeTime(startTime)) {
        events.push(weeklySlot.getCalendarEvent());
      }
      weeklySlot.advanceWeek();
    }
    if (shouldBreak) {
      break;
    }
  }
  return events;
};

const getCalendarEventsFromSlots = async (slots: ProviderScheduleSlot[], range: {start: Date, end: Date}): Promise<CalendarEvent[]> => {
  const eventsPromises = slots.map((slot) => {
    return getCalendarEventsFromSlot(slot, range);
  });
  const allEvents = await Promise.all(eventsPromises);
  const flattened = allEvents.flat();
  flattened.sort((a, b) => a.start.getTime() - b.start.getTime());
  return flattened;
};

const localizer = momentLocalizer(moment);
function ProviderScheduleCalendar({
  slots,
}: {
  slots: ProviderScheduleSlot[],
}) {
  const [calendarView, setCalendarView] = useState<RbcView>('month');
  const [dateRange, setDateRange] = useState(getDefaultStartAndEndDates());
  const [slotCalendarEvents, setSlotCalendarEvents] = useState<CalendarEvent[]>([]);

  const onRangeChange = (range: Date[] | {start: Date, end: Date}) => {
    const { start, end } = getStartAndEndDatesFromRange(range);
    setDateRange({ start, end });
  }

  const updateCalendarEvents = async () => {
    const slotsToDisplay = slots.filter((slot) => !slot.deleted_at);
    const events = await getCalendarEventsFromSlots(slotsToDisplay, dateRange);
    setSlotCalendarEvents(events);
  };

  useEffect(() => {
    updateCalendarEvents();
  }, [slots, dateRange]);

  const getEventStyle = () => {
    return {
      style: {
        backgroundColor: '#add8e6',
        color: 'black',
      },
    };
  };

  const onViewChange = (view: RbcView) => {
    setCalendarView(view);
  };

  // `backgroundEvents` don't show up in the 'month' or 'agenda' views, so we show slot blocks
  // as `events` in those views and background otherwise.
  const showSlotCalendarEventsInBackground = calendarView !== 'month' && calendarView !== 'agenda';
  const events = showSlotCalendarEventsInBackground ? [] : slotCalendarEvents;
  const backgroundEvents = showSlotCalendarEventsInBackground ? slotCalendarEvents : [];

  return (
    <div className="mb-5">
      <Calendar 
        localizer={ localizer }
        onRangeChange={ onRangeChange }
        events={ events }
        backgroundEvents={ backgroundEvents }
        eventPropGetter={ getEventStyle }
        onView={ onViewChange }
        style={ {
          minHeight: '500px',
          overflow: 'hidden',
          resize: 'vertical',
        } }
      />
    </div>
  )
};


export default ProviderScheduleCalendar;