import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { get } from 'webapp-common/api/RestApi';
import Provider from 'webapp-common/models/Provider';
import Loading from '../components/Loading';


interface ProviderRowProps {
  provider: Provider,
}

function ProviderRow({provider}: ProviderRowProps) {
  const providerLink = `/providers/${provider.id}`;
  return (
    <tr>
      <td><Link to={providerLink}>{ provider.id }</Link></td>
      <td>{ provider.email }</td>
      <td>{ provider.first_name }</td>
      <td>{ provider.last_name }</td>
    </tr>
  );
}

const getProviders = async () => {
  return await get<Provider[]>('/providers');
};

function ProvidersRoute() {
  const queryClient = useQueryClient();

  const {
    data: providers,
    isLoading,
  } = useQuery(['providers'], getProviders, {
    // So that if we click a link directly to the provider, we don't
    // have to re-fetch to show it (it will re-fetch in the background tho).
    onSuccess: (providers) => {
      providers.forEach((provider) => {
        queryClient.setQueryData(['provider', provider.id], provider);
      });
    }
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!providers) {
    return <h1>Uh-oh!</h1>
  }


  return (
    <div>
      <Row className="justify-content-center">
        <Col sm="12" lg="10">
          <Table hover bordered size="sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
              </tr>
            </thead>
            <tbody>
              {providers.map((p) => {
                return <ProviderRow key={ p.id } provider={ p } />
              })}
            </tbody>
          </Table>
          <Link to="/providers/create">Create Provider</Link>
        </Col>
      </Row>
    </div>
  );
}

export default ProvidersRoute;