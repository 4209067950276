import { ChangeEventHandler, FormEventHandler, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';

import { del, get, patch, post } from 'webapp-common/api/RestApi';
import DayOfWeek, { DAYS_OF_WEEK } from 'webapp-common/types/DayOfWeek';
import NaiveDate from 'webapp-common/types/NaiveDate';
import NaiveTime from 'webapp-common/types/NaiveTime';
import Provider from 'webapp-common/models/Provider';
import ProviderStateLicense from 'webapp-common/models/ProviderStateLicense';
import ProviderScheduleSlot from 'webapp-common/models/ProviderScheduleSlot';
import UsState, { getLabelForStateCode, US_STATES } from 'webapp-common/types/UsState';

import Loading from '../components/Loading';
import ProviderScheduleCalendar from '../components/ProviderScheduleCalendar';


const SLOT_OPTIONS = [
  {display: '4a - 8a PT', start_time: '04:00:00', duration: 240},
  {display: '8a - 12p PT', start_time: '08:00:00', duration: 240},
  {display: '12p - 4p PT', start_time: '12:00:00', duration: 240},
  {display: '4p - 8p PT', start_time: '4:00:00', duration: 240},
];

type ProviderScheduleSlotFormData = {
  start_time: string,
  duration: number,
  start_date: string,
  end_date: string | null,
  placement_day: DayOfWeek,
}

interface ProviderScheduleSlotFormProps {
  startTime?: string | null,
  duration?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  placementDay?: DayOfWeek | null,
  isSubmitting: boolean,
  errorMessage?: string,
  onSubmit: (slotFormData: ProviderScheduleSlotFormData) => Promise<void>,
};

interface ParsedProviderScheduleSlot {
  id: string,
  provider_id: string,
  start_time: NaiveTime,
  duration: number,
  start_date: NaiveDate,
  end_date: NaiveDate | null,
  placement_day: DayOfWeek,
  deleted_at: Date | null,
}

const getParsedProviderScheduleSlot = (slot: ProviderScheduleSlot): ParsedProviderScheduleSlot => {
  return {
    id: slot.id,
    provider_id: slot.provider_id,
    start_time: NaiveTime.fromString(slot.start_time),
    duration: slot.duration,
    start_date: NaiveDate.fromString(slot.start_date),
    end_date: slot.end_date ? NaiveDate.fromString(slot.end_date) : null,
    placement_day: slot.placement_day,
    deleted_at: slot.deleted_at,
  };
}

function ProviderScheduleSlotForm({
  onSubmit,
  startTime: initialStartTime,
  duration: initialDuration,
  startDate: initialStartDate,
  endDate: initialEndDate,
  placementDay: initialPlacementDay,
  errorMessage,
  isSubmitting,
}: ProviderScheduleSlotFormProps) {
  const initialSlotIdx = SLOT_OPTIONS.findIndex((slot) => {
    return slot.start_time === initialStartTime && slot.duration === initialDuration;
  });
  const [slotIdx, setSlotIdx] = useState<number | null>(initialSlotIdx > -1 ? initialSlotIdx : null);
  const [startDate, setStartDate] = useState<string | null>(initialStartDate || null);
  const [endDate, setEndDate] = useState<string | null>(initialEndDate || null);
  const [placementDay, setPlacementDay] = useState<DayOfWeek | null>(initialPlacementDay || null);

  const handleSelectSlot: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value ? parseInt(event.target.value) : null;
    setSlotIdx(value);
  };

  const handleStartDateChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    setStartDate(value || null);
  };

  const handleEndDateChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    setEndDate(value || null);
  };

  const handleSelectPlacementDay: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value ? (event.target.value as DayOfWeek) : null;
    setPlacementDay(value);
  }

  const submitForm: FormEventHandler = async (event) => {
    event.preventDefault();
    if (!slotIdx || !placementDay || !startDate) {
      return;
    }
    const slot = SLOT_OPTIONS[slotIdx];
    const slotFormData = {
      start_time: slot.start_time,
      duration: slot.duration,
      start_date: startDate,
      end_date: endDate,
      placement_day: placementDay,
    };
    onSubmit(slotFormData);
  };

  const slotIndexSelectValue = slotIdx === null ? '' : slotIdx.toString();
  const isSubmitDisabled = isSubmitting || !slotIndexSelectValue || !startDate || !placementDay;

  const startDateValue = startDate || '';
  const endDateValue = endDate || '';
  const minStartDate = moment().format('YYYY-MM-DD');
  const minEndDate = startDate ? startDate : minStartDate;
  const placementDayValue = placementDay || '';

  return (
    <div>
      {errorMessage &&
        <Alert variant="danger">
          { errorMessage }
        </Alert>
      }
      <Form onSubmit={submitForm}>
        <Form.Group className="mb-3" controlId="time-slot">
          <Form.FloatingLabel label="Time Slot">
            <Form.Select value={ slotIndexSelectValue } onChange={ handleSelectSlot }>
              <option></option>
              {SLOT_OPTIONS.map((slotOption, idx) => {
                return <option key={idx.toString()} value={idx.toString()}>{ slotOption.display }</option>
              })}
            </Form.Select>
          </Form.FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="placement-day">
          <Form.FloatingLabel label="Day Sequence">
            <Form.Select value={ placementDayValue } onChange={ handleSelectPlacementDay }>
              <option></option>
              {DAYS_OF_WEEK.map((day) => {
                return <option key={day} value={day}>{ renderDaysTriplet(day, ' / ') }</option>
              })}
            </Form.Select>
          </Form.FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="start-date">
          <Form.FloatingLabel label="Start Date">
            <Form.Control type="date" min={ minStartDate } value={startDateValue} onChange={ handleStartDateChange } />
          </Form.FloatingLabel>
          <Form.Text muted>
            The provider's first slot of availability will be on the first "Placement Day" after this start date.
            For example, if the start date happens to be a Sunday and the "Placement Day" is Thursday, then provider's first appointments would be on the first Thursday after the Sunday start date.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="end-date">
          <Form.FloatingLabel label="End Date">
            <Form.Control type="date" disabled={ Boolean(!startDate) } min={ minEndDate } value={endDateValue} onChange={ handleEndDateChange } />
          </Form.FloatingLabel>
          <Form.Text muted>
            This is optional. You can leave this end date undetermined and edit the recurring slot in the future to provide an end date.
          </Form.Text>
        </Form.Group>
        <Button disabled={isSubmitDisabled} type="submit">{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
      </Form>
    </div>
  );
};


const compareSlots = (slotA: ProviderScheduleSlot, slotB: ProviderScheduleSlot): number => {
  const slotAStr = `${slotA.start_date}-${slotA.start_time}`;
  const slotBStr = `${slotB.start_date}-${slotB.start_time}`;
  if (slotAStr < slotBStr) {
    return -1;
  } else if (slotAStr > slotBStr) {
    return 1;
  }
  return 0;
};


interface CreateSlotParams {
  providerId: string,
  slotFormData: ProviderScheduleSlotFormData,
}

const createSlot = async ({providerId, slotFormData}: CreateSlotParams) => {
  const body = {
    provider_id: providerId,
    ...slotFormData,
  };
  return await post<ProviderScheduleSlot>('/provider-schedule-slots', body);
};

type PatchProviderScheduleSlotBody = {
  start_time?: string,
  duration?: number,
  start_date?: string,
  end_date?: string | null,
  placement_day?: DayOfWeek,
}

interface UpdateSlotParams {
  slotId: string,
  body: PatchProviderScheduleSlotBody,
}

const updateSlot = async ({slotId, body}: UpdateSlotParams) => {
  return await patch<ProviderScheduleSlot>(`/provider-schedule-slots/${slotId}`, body);
};

const deleteSlot = async (providerScheduleSlotId: string) => {
  return await del<ProviderScheduleSlot>(`/provider-schedule-slots/${providerScheduleSlotId}`);
}

// NaiveTime: HH:MM:SS
const renderHourFromNaiveTime = (naiveTime: NaiveTime): string => {
  const clockHours = naiveTime.getClockHours();
  const ampm = naiveTime.isAm() ? 'a' : 'p';
  return `${clockHours}${ampm}`;
}

const renderTimeSlot = (startTime: NaiveTime, duration: number): string => {
  const endTime = startTime.addMinutes(duration);
  const startTimeStr = renderHourFromNaiveTime(startTime);
  const endTimeStr = renderHourFromNaiveTime(endTime);
  return `${startTimeStr} - ${endTimeStr} PT`;
}

const getDaysTriplet = (start: DayOfWeek): DayOfWeek[] => {
  const dayIndex = DAYS_OF_WEEK.indexOf(start);
  return [
    start,
    DAYS_OF_WEEK[(dayIndex + 2) % DAYS_OF_WEEK.length],
    DAYS_OF_WEEK[(dayIndex + 4) % DAYS_OF_WEEK.length],
  ];
}

const renderDaysTriplet = (start: DayOfWeek, sep: string = ', '): string => {
  const triplet = getDaysTriplet(start);
  return triplet.join(sep);
}

function ProviderScheduleSlotsTable({
  slots,
  setEditingSlot,
  deleteSlotById,
  setShowAddSlot,
}: {
  slots: ProviderScheduleSlot[],
  setEditingSlot: (slot: ProviderScheduleSlot) => void,
  deleteSlotById: (slotId: string) => void,
  setShowAddSlot: (show: boolean) => void,
}) {
  const slotsInTable = slots.filter((slot) => !slot.deleted_at);
  slotsInTable.sort(compareSlots);
  const parsedSlots = slotsInTable.map((slot) => {
    return {slot, parsed: getParsedProviderScheduleSlot(slot)};
  });

  return (
    <div>
      <Table bordered>
        <thead>
          <tr>
            <th>Start Date</th>
            <th>Day Sequence</th>
            <th>Time Slot</th>
            <th>End Date</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {parsedSlots.map(({slot, parsed}) => {
            return (
              <tr key={slot.id}>
                <td>{ parsed.start_date.asString() }</td>
                <td>{ renderDaysTriplet(parsed.placement_day) }</td>
                <td>{ renderTimeSlot(parsed.start_time, parsed.duration) }</td>
                <td>{ parsed.end_date ? parsed.end_date.asString() : '' }</td>
                <td><Button onClick={() => setEditingSlot(slot)} variant="link" className="p-0">edit</Button></td>
                <td><CloseButton onClick={() => deleteSlotById(slot.id) } /></td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button onClick={() => setShowAddSlot(true)}>Add Slot</Button>
    </div>
  )
}

interface AddProviderStateLicenseFormProps {
  onSubmit: (stateCode: UsState) => void,
  disabledStates?: UsState[],
  isSubmitting: boolean,
}

function AddProviderStateLicenseForm({
  onSubmit,
  disabledStates,
  isSubmitting,
}: AddProviderStateLicenseFormProps) {
  const [stateCode, setStateCode] = useState<UsState | null>(null);

  const handleSelectStateCode: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value ? (event.target.value as UsState) : null;
    setStateCode(value);
  }

  const submitForm: FormEventHandler = async (event) => {
    event.preventDefault();
    if (!stateCode) {
      return;
    }
    onSubmit(stateCode);
  };

  const disableStates = disabledStates || [];
  const stateCodeValue = stateCode || '';
  const isSubmitDisabled = isSubmitting || !stateCode;

  return (
    <div>
      <Form onSubmit={submitForm}>
        <Form.Group className="mb-3" controlId="state-code">
          <Form.FloatingLabel label="State">
            <Form.Select value={ stateCodeValue } onChange={ handleSelectStateCode }>
              <option></option>
              {US_STATES.map(({code, label}) => {
                const isDisabled = disableStates.some((state) => state === code);
                return <option key={code} disabled={isDisabled} value={code}>{ label }</option>;
              })}
            </Form.Select>
          </Form.FloatingLabel>
        </Form.Group>
        <Button disabled={isSubmitDisabled} type="submit">
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </Form>
    </div>
  )
}

interface AddStateLicenseParams {
  providerId: string,
  stateCode: UsState,
}

const addStateLicense = async ({providerId, stateCode}: AddStateLicenseParams) => {
  const body = {
    provider_id: providerId,
    state_code: stateCode,
  };
  return await post<ProviderStateLicense>('/provider-state-licenses', body);
};

interface SuccessMessage {
  success: boolean,
}

const deleteStateLicense = async (providerStateLicenseId: string) => {
  return await del<SuccessMessage>(`/provider-state-licenses/${providerStateLicenseId}`);
};


function ProviderRoute() {
  const { providerId } = useParams();
  const queryClient = useQueryClient();

  const [showAddLicense, setShowAddLicense] = useState(false);
  const [licenseToDelete, setLicenseToDelete] = useState<ProviderStateLicense | null>(null);
  const [showAddSlot, setShowAddSlot] = useState(false);
  const [editingSlot, setEditingSlot] = useState<ProviderScheduleSlot | null>(null);

  const addStateLicenseMutation = useMutation(addStateLicense, {
    onSuccess(license) {
      queryClient.setQueryData(['provider-state-licenses', providerId], (licenses: ProviderStateLicense[] | undefined) => {
        return [...(licenses || []), license];
      });
    },
  });

  const deleteStateLicenseMutation = useMutation(deleteStateLicense, {
    onSuccess(_, providerStateLicenseId) {
      queryClient.setQueryData(['provider-state-licenses', providerId], (licenses: ProviderStateLicense[] | undefined) => {
        return (licenses || []).filter((lic) => lic.id !== providerStateLicenseId);
      });
    },
  });

  const addSlotMutation = useMutation(createSlot, {
    onSuccess: (slot) => {
      queryClient.setQueryData(['provider-schedule-slots', providerId], (slots: ProviderScheduleSlot[] | undefined) => {
        return [...(slots || []), slot];
      });
    },
  });
  const updateQueryData = (slot: ProviderScheduleSlot, maybeSlots: ProviderScheduleSlot[] | undefined) => {
    const slots = maybeSlots || [];
    const slotIdx = slots.findIndex((s) => s.id === slot.id);
    if (slotIdx === -1) {
      return slots;
    }
    return [
      ...slots.slice(0, slotIdx),
      slot,
      ...slots.slice(slotIdx + 1),
    ];
  };
  const updateSlotMutation = useMutation(updateSlot, {
    onSuccess(slot) {
      queryClient.setQueryData(['provider-schedule-slots', providerId], (maybeSlots: ProviderScheduleSlot[] | undefined) => {
        return updateQueryData(slot, maybeSlots);
      });
    },
  });
  const deleteSlotMutation = useMutation(deleteSlot, {
    onSuccess: (slot) => {
      queryClient.setQueryData(['provider-schedule-slots', providerId], (maybeSlots: ProviderScheduleSlot[] | undefined) => {
        return updateQueryData(slot, maybeSlots);
      });
    },
  });

  const {
    data: provider,
    isLoading: isLoadingProvider,
  } = useQuery(['provider', providerId], async () => {
    if (!providerId) {
      throw new Error('Missing provider ID');
    }
    return await get<Provider>(`/providers/${providerId}`);
  });

  const {
    data: licenses,
    isLoading: isLoadingLicenses,
  } = useQuery(['provider-state-licenses', providerId], async () => {
    if (!providerId) {
      throw new Error('Missing provider ID');
    }
    return await get<ProviderStateLicense[]>('/provider-state-licenses', {provider_id: providerId});
  })

  const {
    data: providerScheduleSlots,
    isLoading: isLoadingProviderScheduleSlots,
  } = useQuery(['provider-schedule-slots', providerId], async () => {
    if (!providerId) {
      throw new Error('Missing provider ID');
    }
    return await get<ProviderScheduleSlot[]>('/provider-schedule-slots', {provider_id: providerId});
  });

  const submitAddStateLicense = async (stateCode: UsState) => {
    if (!providerId) {
      throw new Error('Missing provider ID');
    }
    await addStateLicenseMutation.mutateAsync({providerId, stateCode});
    setShowAddLicense(false);
  }

  const submitDeleteStateLicense = async (providerStateLicenseId: string) => {
    await deleteStateLicenseMutation.mutateAsync(providerStateLicenseId);
    setLicenseToDelete(null);
  };

  const submitAddSlot = async (slotFormData: ProviderScheduleSlotFormData) => {
    if (!providerId) {
      throw new Error('Missing provider ID');
    }
    // TODO: Check if slot already exists?
    await addSlotMutation.mutateAsync({providerId, slotFormData});
    setShowAddSlot(false);
  };

  const submitUpdateSlot = async (slotFormData: ProviderScheduleSlotFormData) => {
    if (!providerId) {
      throw new Error('Missing provider ID');
    }
    if (!editingSlot) {
      throw new Error('Missing slot to edit');
    }
    const slotId = editingSlot.id;
    const body = {
      start_time: slotFormData.start_time !== editingSlot.start_time ? slotFormData.start_time : undefined,
      duration: slotFormData.duration !== editingSlot.duration ? slotFormData.duration : undefined,
      start_date: slotFormData.start_date !== editingSlot.start_date ? slotFormData.start_date : undefined,
      end_date: slotFormData.end_date !== editingSlot.end_date ? slotFormData.end_date : undefined,
      placement_day: slotFormData.placement_day !== editingSlot.placement_day ? slotFormData.placement_day : undefined,
    };
    await updateSlotMutation.mutateAsync({slotId, body});
    setEditingSlot(null);
  };


  const deleteSlotById = (slotId: string) => {
    if (!providerId) {
      throw new Error('Missing provider ID');
    }
    deleteSlotMutation.mutate(slotId);
  }

  const isLoading = isLoadingProvider || isLoadingLicenses || isLoadingProviderScheduleSlots;

  if (isLoading) {
    return <Loading />;
  }

  if (!provider || !licenses || !providerScheduleSlots) {
    return <h1>Uh-oh!</h1>
  }

  const licensesList = licenses.length > 0 ? (
    <ListGroup className="mb-4">
      {licenses.map((license) => {
        const stateCode = license.state_code;
        const stateLabel = getLabelForStateCode(stateCode);
        return (
          <ListGroup.Item className="d-flex justify-content-between" key={ stateCode } style={ {maxWidth: '300px'} }>
            <div className="me-auto">
              { stateLabel }
            </div>
            <CloseButton onClick={ () => setLicenseToDelete(license) } />
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  ) : (
    <p><em>No state licenses added.</em></p>
  );

  const licenseToDeleteStateLabel = licenseToDelete ? getLabelForStateCode(licenseToDelete.state_code) : '';

  return (
    <div>
      <Row className="justify-content-center">
        <Col sm="12" lg="10">
          <Card className="mb-4">
            <Card.Header>{provider.first_name} {provider.last_name}</Card.Header>
            <Card.Body>
              <p><strong>ID:</strong> { provider.id }</p>
              <p><strong>Email:</strong> { provider.email }</p>
              <p><strong>Time Zone:</strong> { provider.time_zone }</p>
            </Card.Body>
          </Card>

          <Card className="mb-4">
            <Card.Header>State Licenses</Card.Header>
            <Card.Body>
              { licensesList }
              <Button onClick={() => setShowAddLicense(true)}>Add License</Button>
            </Card.Body>
            <Modal show={showAddLicense} onHide={() => setShowAddLicense(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Add State License</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AddProviderStateLicenseForm
                  onSubmit={ submitAddStateLicense }
                  disabledStates={licenses.map(({state_code}) => state_code)}
                  isSubmitting={ addStateLicenseMutation.isLoading }
                />
              </Modal.Body>
            </Modal>
            <Modal show={Boolean(licenseToDelete)} onHide={() => setLicenseToDelete(null)}>
              <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to remove the state license for <strong>{ licenseToDeleteStateLabel }</strong>?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" disabled={ deleteStateLicenseMutation.isLoading } onClick={() => setLicenseToDelete(null)}>No</Button>
                <Button variant="danger" disabled={ deleteStateLicenseMutation.isLoading } onClick={() => submitDeleteStateLicense((licenseToDelete as ProviderStateLicense).id)}>Yes</Button>
              </Modal.Footer>
            </Modal>
          </Card>

          <Card className="mb-4">
            <Card.Header>Schedule</Card.Header>
            <Card.Body>
              <ProviderScheduleCalendar slots={ providerScheduleSlots } />
              <ProviderScheduleSlotsTable
                slots={ providerScheduleSlots }
                setEditingSlot={ setEditingSlot }
                deleteSlotById={ deleteSlotById }
                setShowAddSlot={ setShowAddSlot }
              />
            </Card.Body>
            <Modal show={showAddSlot} onHide={() => setShowAddSlot(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Add Slot</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProviderScheduleSlotForm
                  isSubmitting={ addSlotMutation.isLoading }
                  onSubmit={ submitAddSlot }
                  errorMessage={ (addSlotMutation.error as Error | undefined)?.message }
                />
              </Modal.Body>
            </Modal>
            <Modal show={Boolean(editingSlot)} onHide={() => setEditingSlot(null)}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Slot</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProviderScheduleSlotForm
                  startTime={ editingSlot?.start_time }
                  duration={ editingSlot?.duration }
                  startDate={ editingSlot?.start_date }
                  endDate={ editingSlot?.end_date }
                  placementDay={ editingSlot?.placement_day }
                  isSubmitting={ updateSlotMutation.isLoading }
                  onSubmit={ submitUpdateSlot }
                  errorMessage={ (updateSlotMutation.error as Error | undefined)?.message }
                />
              </Modal.Body>
            </Modal>
          </Card>
        </Col>
      </Row>
    </div>
  )

}

export default ProviderRoute;