import {
  Navigate,
  useLocation,
  useNavigate,
  Route,
  Routes,
} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { useQuery, useQueryClient } from 'react-query';

import { useAuth } from 'webapp-common/auth/AuthProvider';

import HomeRoute from './routes/HomeRoute';
import LoginRoute from './routes/LoginRoute';

import Loading from './components/Loading';
import Navbar from './components/Navbar';
import ProvidersRoute from './routes/ProvidersRoute';
import CreateProviderRoute from './routes/CreateProviderRoute';
import ProviderRoute from './routes/ProviderRoute';

const loadAuth = async (auth: any) => {
  await auth.load();
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const queryClient = useQueryClient();

  const auth = useAuth();

  const {
    isLoading: isLoadingAuth,
  } = useQuery('auth', async () => {
    await loadAuth(auth);
  });

  if (isLoadingAuth) {
    return <Loading />;
  }

  const isAuthenticated = auth.user !== null;

  if (!isAuthenticated && pathname !== '/login') {
    return <Navigate to="/login" state={ {from: location} } replace />;
  }

  const signOut = async () => {
    await auth.signout();
    queryClient.clear();
    navigate('/login');
  };

  return (
    <div>
      <Navbar isAuthenticated={isAuthenticated} signOut={signOut} />
      <Container fluid className="pt-5 pb-5">
        <Routes>
          <Route path="/login" element={<LoginRoute />} />
          <Route path="/" element={<HomeRoute />} />
          <Route path="/providers" element={<ProvidersRoute />} />
          <Route path="/providers/create" element={<CreateProviderRoute />} />
          <Route path="/providers/:providerId" element={<ProviderRoute />} />
        </Routes>
      </Container>
    </div>
  );
}

export default App;
