
// Pass this in (or load) from configuration
let API_HOST = '';

interface ConfigurationOptions {
  apiHost?: string,
}

const configure = (opts: ConfigurationOptions) => {
  if (opts.apiHost) {
    API_HOST = opts.apiHost;
  }
};

type RequestMethod = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

type ApiError = {
  error: string,
}

type RequestBodyValue = string | number | boolean | null | Date | RequestBodyValue[];

interface RequestBody {
  [key: string]: RequestBodyValue | RequestBody,
}

interface RequestQueryParams {
  [key: string]: string | number | boolean,
}

interface RequestOptions {
  method: RequestMethod,
  body?: RequestBody,
  queryParams?: RequestQueryParams,
}

const request = async function<T>(endpoint: string, options: RequestOptions): Promise<T> {
  if (!API_HOST) {
    throw new Error('Missing API host');
  }
  const url = new URL(`${API_HOST}${endpoint}`);
  if (options.queryParams) {
    const searchParams = new URLSearchParams();
    Object.entries(options.queryParams).forEach(([key, value]) => {
      searchParams.set(key, value.toString());
    })
    url.search = searchParams.toString();
  }
  const urlString = url.toString();
  const opts: RequestInit = {
    method: options.method,
    mode: 'cors',
    credentials: 'include',
  };
  if (options.body) {
    opts.body = JSON.stringify(options.body);
    opts.headers = {
      'Content-Type': 'application/json',
    };
  }
  const response: Response = await fetch(urlString, opts);
  if (!response.ok) {
    let jsonResponse;
    try {
      jsonResponse = await response.json();
    } catch {
      jsonResponse = {};
    }
    const errorResponse = {
      error: jsonResponse.error || "Unknown error",
    } as ApiError;
    throw errorResponse;
  }
  const value = await response.json();
  return value as T;
};

const post = async function<T>(endpoint: string, body?: RequestBody): Promise<T> {
  const opts = {
    method: 'POST' as RequestMethod,
    body,
  };
  return await request(endpoint, opts);
}

const get = async function<T>(endpoint: string, queryParams?: RequestQueryParams): Promise<T> {
  const opts = {
    method: 'GET' as RequestMethod,
    queryParams,
  };
  return await request(endpoint, opts);
}

const patch = async function<T>(endpoint: string, body?: RequestBody): Promise<T> {
  const opts = {
    method: 'PATCH' as RequestMethod,
    body,
  };
  return await request(endpoint, opts);
}

const del = async function<T>(endpoint: string, queryParams?: RequestQueryParams): Promise<T> {
  const opts = {
    method: 'DELETE' as RequestMethod,
    queryParams,
  };
  return await request(endpoint, opts);
}

export { 
  type ApiError,
  type RequestQueryParams,
  configure,
  del,
  get,
  patch,
  post,
  request,
};