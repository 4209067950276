// 50 states + DC, Guam, PR, Virgin Islands

type UsState =
  'AK' | // Alaska
  'AL' | // Alabama
  'AR' | // Arkansas
  'AZ' | // Arizona
  'CA' | // California
  'CO' | // Colorado
  'CT' | // Connecticut
  'DC' | // District of Columbia
  'DE' | // Delaware
  'FL' | // Florida
  'GA' | // Georgia
  'HI' | // Hawaii
  'IA' | // Iowa
  'ID' | // Idaho
  'IL' | // Illinois
  'IN' | // Indiana
  'KS' | // Kansas
  'KY' | // Kentucky
  'LA' | // Louisiana
  'MA' | // Massachusetts
  'MD' | // Maryland
  'ME' | // Maine
  'MI' | // Michigan
  'MN' | // Minnesota
  'MO' | // Missouri
  'MS' | // Mississippi
  'MT' | // Montana
  'NC' | // North Carolina
  'ND' | // North Dakota
  'NE' | // Nebraska
  'NH' | // New Hampshire
  'NJ' | // New Jersey
  'NM' | // New Mexico
  'NV' | // Nevada
  'NY' | // New York
  'OH' | // Ohio
  'OK' | // Oklahoma
  'OR' | // Oregon
  'PA' | // Pennsylvania
  'RI' | // Rhode Island
  'SC' | // South Carolina
  'SD' | // South Dakota
  'TN' | // Tennessee
  'TX' | // Texas
  'UT' | // Utah
  'VA' | // Virginia
  'VT' | // Vermont
  'WA' | // Washington
  'WI' | // Wisconsin
  'WV' | // West Virginia
  'WY' | // Wyoming
  'GU' | // Guam
  'PR' | // Puerto Rico
  'VI'; // Virgin Islands

const US_STATES = [
  { code: 'AK', label: 'Alaska' },
  { code: 'AL', label: 'Alabama' },
  { code: 'AR', label: 'Arkansas' },
  { code: 'AZ', label: 'Arizona' },
  { code: 'CA', label: 'California' },
  { code: 'CO', label: 'Colorado' },
  { code: 'CT', label: 'Connecticut' },
  { code: 'DC', label: 'District of Columbia' },
  { code: 'DE', label: 'Delaware' },
  { code: 'FL', label: 'Florida' },
  { code: 'GA', label: 'Georgia' },
  { code: 'HI', label: 'Hawaii' },
  { code: 'IA', label: 'Iowa' },
  { code: 'ID', label: 'Idaho' },
  { code: 'IL', label: 'Illinois' },
  { code: 'IN', label: 'Indiana' },
  { code: 'KS', label: 'Kansas' },
  { code: 'KY', label: 'Kentucky' },
  { code: 'LA', label: 'Louisiana' },
  { code: 'MA', label: 'Massachusetts' },
  { code: 'MD', label: 'Maryland' },
  { code: 'ME', label: 'Maine' },
  { code: 'MI', label: 'Michigan' },
  { code: 'MN', label: 'Minnesota' },
  { code: 'MO', label: 'Missouri' },
  { code: 'MS', label: 'Mississippi' },
  { code: 'MT', label: 'Montana' },
  { code: 'NC', label: 'North Carolina' },
  { code: 'ND', label: 'North Dakota' },
  { code: 'NE', label: 'Nebraska' },
  { code: 'NH', label: 'New Hampshire' },
  { code: 'NJ', label: 'New Jersey' },
  { code: 'NM', label: 'New Mexico' },
  { code: 'NV', label: 'Nevada' },
  { code: 'NY', label: 'New York' },
  { code: 'OH', label: 'Ohio' },
  { code: 'OK', label: 'Oklahoma' },
  { code: 'OR', label: 'Oregon' },
  { code: 'PA', label: 'Pennsylvania' },
  { code: 'RI', label: 'Rhode Island' },
  { code: 'SC', label: 'South Carolina' },
  { code: 'SD', label: 'South Dakota' },
  { code: 'TN', label: 'Tennessee' },
  { code: 'TX', label: 'Texas' },
  { code: 'UT', label: 'Utah' },
  { code: 'VA', label: 'Virginia' },
  { code: 'VT', label: 'Vermont' },
  { code: 'WA', label: 'Washington' },
  { code: 'WI', label: 'Wisconsin' },
  { code: 'WV', label: 'West Virginia' },
  { code: 'WY', label: 'Wyoming' },

  { code: 'GU', label: 'Guam' },
  { code: 'PR', label: 'Puerto Rico' },
  { code: 'VI', label: 'Virgin Islands' },
] as {
  code: UsState,
  label: string,
}[];

const isValidStateCode = (stateCode: string): boolean => {
  return US_STATES.some(({code}) => code === stateCode);
}

const getLabelForStateCode = (stateCode: UsState): string | null => {
  const elem = US_STATES.find(({code}) => code === stateCode);
  return elem ? elem.label : null;
}

export default UsState;

export {
  US_STATES,
  isValidStateCode,
  getLabelForStateCode,
}