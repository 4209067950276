import * as React from 'react';
import {
  createContext,
  useContext,
  useState,
  ReactNode,
} from 'react';

import { ApiError, get, post } from '../api/RestApi';
import User from '../models/User';

/**
 * For some background on the approach here, see:
 * https://reactrouter.com/docs/en/v6/examples/auth
 * and
 * https://usehooks.com/useAuth/
 */

interface UserInfo {
  sub: string,
  role: string,
  first_name: string,
  last_name: string,
  email: string,
  is_email_verified: boolean,
};

interface SuccessMessage {
  success: boolean,
}

type Role = 'patient' | 'provider' | 'admin';


const userInfoToUser = (userInfo: UserInfo): User => {
  return {
    id: userInfo.sub,
    firstName: userInfo.first_name,
    lastName: userInfo.last_name,
    email: userInfo.email,
    isEmailVerified: userInfo.is_email_verified,
  };
}

interface AuthContextType {
  user: User | null,
  signin: (username: string, password: string, role: Role) => Promise<void>,
  signout: () => Promise<void>,
  load: () => Promise<void>,
}

function useProvideAuth(): AuthContextType {
  const [user, setUser] = useState<User | null>(null);

  const load = async () => {
    try {
      const userInfo = await get<UserInfo>("/userinfo");
      const user = userInfoToUser(userInfo);
      setUser(user);
    } catch (e) {
      // TODO: Anything we can do with the error?
      setUser(null);
    }
  }

  const signin = async (username: string, password: string, role: Role) => {
    const body = {
      username,
      password,
      role,
    };
    try {
      const userInfo = await post<UserInfo>("/login", body);
      const user = userInfoToUser(userInfo);
      setUser(user);
    } catch (e) {
      const error = (e as ApiError).error;
      throw new Error(error);
    }
  };

  const signout = async () => {
    try {
      await get<SuccessMessage>("/logout");
      setUser(null);
    } catch (e) {
      const error = (e as ApiError).error;
      throw new Error(error);
    }
  };

  return {
    user,
    signin,
    signout,
    load,
  };
}

const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: {children: ReactNode }) {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      { children }
    </AuthContext.Provider>
  )
}

function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
export {
  useAuth,
  type AuthContextType,
};