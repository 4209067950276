type ProviderTimeZone =
  'America/Los_Angeles' |
  'America/Denver' |
  'America/Chicago' |
  'America/New_York' |
  'Pacific/Honolulu' |
  'America/Anchorage' |
  'America/Puerto_Rico';

export default ProviderTimeZone;

const PROVIDER_TIME_ZONES: ProviderTimeZone[] = [
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Puerto_Rico',
];

export {
  PROVIDER_TIME_ZONES,
};

// // Requires `moment-timezone`
// const getTimeZoneOffset = (tz: ProviderTimeZone, date?: Date): number => {
//   const d = date || new Date();
//   switch (tz) {
//     case 'America/Los_Angeles':
//       return -7;
//   }
// }