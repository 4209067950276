import { FormEventHandler, useState } from 'react';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useMutation, useQueryClient } from 'react-query';

import { post } from 'webapp-common/api/RestApi';
import Provider from 'webapp-common/models/Provider';
import ProviderTimeZone, { PROVIDER_TIME_ZONES } from 'webapp-common/types/ProviderTimeZone';
import { Navigate } from 'react-router-dom';

type PostProvidersBody = {
  first_name: string,
  last_name: string,
  email: string,
  password: string,
  time_zone: string,
};

const createProvider = async (body: PostProvidersBody) => {
  return await post<Provider>('/providers', body);
}

function CreateProviderRoute() {
  const queryClient = useQueryClient();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [timeZone, setTimeZone] = useState<ProviderTimeZone>('America/Los_Angeles');

  const submitMutation = useMutation(createProvider, {
    onSuccess: (provider) => {
      queryClient.invalidateQueries('providers');
      queryClient.setQueryData(['provider', provider.id], provider);
    },
  });

  const submitCreateProvider: FormEventHandler = async (event) => {
    event.preventDefault();
    submitMutation.mutate({
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      time_zone: timeZone as string,
    });
  }

  const isSubmitting = submitMutation.isLoading;
  const submitText = isSubmitting ? 'Creating Provider...' : 'Create Provider';

  if (submitMutation.isSuccess) {
    const providerId = submitMutation.data.id;
    const providerLink = `/providers/${providerId}`;
    return <Navigate to={ providerLink } />;
  }

  return (
    <Row className="justify-content-center">
      <Col sm="10" lg="6">
        <Form onSubmit={submitCreateProvider}>
          <FloatingLabel className="mb-3" controlId="first-name" label="First Name">
            <Form.Control required type="text" placeholder="First Name" value={ firstName } onChange={(e) => setFirstName(e.target.value)} />
          </FloatingLabel>
          <FloatingLabel className="mb-3" controlId="last-name" label="Last Name">
            <Form.Control required type="text" placeholder="Last Name" value={ lastName } onChange={(e) => setLastName(e.target.value)} />
          </FloatingLabel>
          <FloatingLabel className="mb-3" controlId="email" label="Email">
            <Form.Control required type="email" placeholder="Email" value={ email } onChange={(e) => setEmail(e.target.value)} />
          </FloatingLabel>
          <FloatingLabel className="mb-3" controlId="password" label="Password">
            <Form.Control required type="password" placeholder="Password" value={ password } onChange={(e) => setPassword(e.target.value)}/>
          </FloatingLabel>
          <FloatingLabel className="mb-3" controlId="time-zone" label="Time Zone">
            <Form.Select onChange={(e) => setTimeZone(e.target.value as ProviderTimeZone)}>
              {PROVIDER_TIME_ZONES.map((timeZone) => {
                return <option key={ timeZone } value={ timeZone }>{ timeZone }</option>
              })}
            </Form.Select>
          </FloatingLabel>
          <Button type="submit" disabled={isSubmitting} className="w-100">{ submitText }</Button>
        </Form>
      </Col>
    </Row>
  )

}

export default CreateProviderRoute;